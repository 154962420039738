import { Box, Grow, useMediaQuery } from "@mui/material";
import { Col, ContentPanel, Row } from "../component_library/layout";
import { ContactInfoSection } from "../components/contact_info_section";
import { Image } from "../component_library/image";
import { Paragraph, ParagraphBody, ParagraphTitle } from "../component_library/paragraph";
import { Localization } from "../component_library/localization";


function DisasterBlasterParagraphTitle({ textColor = "white", borderColor = "white", backgroundColor = "black", children }) {
    return (
        <Box sx={{ marginBottom: "2rem", }}>
            <ParagraphTitle
                color={textColor}
                center={true}
                sx={{
                    border: "0.2rem solid #fff",
                    borderRadius: "1rem",
                    padding: "0.4em",
                    boxShadow: `0 0 .2rem #fff, 0 0 .2rem #fff, 0 0 1rem ${borderColor}, 0 0 0.4rem ${borderColor}, 0 0 1.4rem ${borderColor}, inset 0 0 0.7rem ${borderColor}`,
                    textShadow: `0 0 .2rem ${textColor}`,
                    backgroundColor: `${backgroundColor}`
                }}>
                {children}
            </ParagraphTitle>
        </Box>
    );
}

function TrailerSection() {

    return (
        <Box sx={{ marginTop: "5rem" }}>
            <ContentPanel backgroundImageSrc={"images/disaster-blaster-background.jpg"}>
                <Row weights={[7, 5]} >
                    <Grow in={true} timeout={1000}>
                        <iframe
                            width="100%"
                            height="360px"
                            src="https://www.youtube.com/embed/eEZpKB00S-g?si=74Zb1quc9Fx1EPvN"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen>
                        </iframe>
                    </Grow>
                    <div title="Disaster Blaster Logo">
                        <Image src="images/disaster_blaster_logo.png" alt="Disaster Blaster Logo" width="100%" />
                    </div>
                </Row>
            </ContentPanel>
        </Box>
    );
}

function AboutGameSection() {
    const paragraphTitleBorderColor = "#fffA8f";
    const paragraphTitleColor = "#ffffd4";
    const paragraphBackgroundColor = "#553676";
    const panelBackgroundColor = "#211132";
    const textBackgroundColor1 = "#72499f";
    const textBackgroundColor2 = "#a16bd5";
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    return (
        <>
            <ContentPanel>
                <Row>
                    <Paragraph>
                        <ParagraphBody center={true}>
                            {Localization.get("disaster_blaster_description")}
                        </ParagraphBody>
                    </Paragraph>
                </Row>

                <Row sx={{ py: "3rem" }}>
                    <iframe
                        src="https://store.steampowered.com/widget/3194600/"
                        frameborder="0"
                        width="646"
                        height="190" />
                </Row>
            </ContentPanel>

            <ContentPanel backgroundImageSrc={"images/db_panel_gradient_background.jpg"}>
                <Row sx={{
                    backgroundColor: `${textBackgroundColor1}`,
                    padding: "2rem",
                    borderRadius: "1rem",
                    boxShadow: "0 0 1rem #000, 0 0 2rem #000",
                }}>
                    <Paragraph>
                        <DisasterBlasterParagraphTitle
                            borderColor={paragraphTitleBorderColor}
                            textColor={paragraphTitleColor}
                            backgroundColor={paragraphBackgroundColor}
                        >
                            {Localization.get("disaster_blaster_build_title")}
                        </DisasterBlasterParagraphTitle>
                        <ParagraphBody center={isSmallScreen}>
                            {Localization.get("disaster_blaster_build_description")}
                        </ParagraphBody>
                    </Paragraph>
                    <div title="Disaster Blaster: build your blaster.">
                        <Image
                            src="gifs/db-store-build.gif"
                            width="100%"
                            sx={{
                                marginTop: isSmallScreen ? "3rem" : "0",
                                borderRadius: "2rem",
                                boxShadow: `0 0 1rem ${paragraphTitleColor}, 0 0 2rem ${paragraphTitleColor}`
                            }}
                        />
                    </div>
                </Row>
            </ContentPanel>

            <ContentPanel backgroundImageSrc={"images/db_panel_background.jpg"}>
                <Row sx={{
                    backgroundColor: `${textBackgroundColor2}`,
                    padding: "2rem",
                    borderRadius: "1rem",
                    boxShadow: "0 0 1rem #000, 0 0 2rem #000",
                }}>
                    <div title="Disaster Blaster: destroy everything else.">
                        <Image
                            src="gifs/db-store-destroy.gif"
                            width="100%"
                            sx={{
                                marginBottom: isSmallScreen ? "3rem" : "0",
                                borderRadius: "2rem",
                                boxShadow: `0 0 1rem ${paragraphTitleColor}, 0 0 2rem ${paragraphTitleColor}`
                            }}
                        />
                    </div>
                    <Paragraph>
                        <DisasterBlasterParagraphTitle
                            borderColor={paragraphTitleBorderColor}
                            textColor={paragraphTitleColor}
                            backgroundColor={paragraphBackgroundColor}
                        >
                            {Localization.get("disaster_blaster_destroy_title")}
                        </DisasterBlasterParagraphTitle>
                        <ParagraphBody center={isSmallScreen}>
                            {Localization.get("disaster_blaster_destroy_description")}
                        </ParagraphBody>
                    </Paragraph>
                </Row>
            </ContentPanel>

            <ContentPanel color={panelBackgroundColor}>
                <Row sx={{
                    backgroundColor: `${textBackgroundColor1}`,
                    padding: "2rem",
                    borderRadius: "1rem",
                    boxShadow: "0 0 1rem #000, 0 0 2rem #000",
                }}>
                    <Paragraph>
                        <DisasterBlasterParagraphTitle
                            borderColor={paragraphTitleBorderColor}
                            textColor={paragraphTitleColor}
                            backgroundColor={paragraphBackgroundColor}
                        >
                            {Localization.get("disaster_blaster_discover_title")}
                        </DisasterBlasterParagraphTitle>
                        <ParagraphBody center={isSmallScreen}>
                            {Localization.get("disaster_blaster_discover_description")}
                        </ParagraphBody>
                    </Paragraph>
                    <div title="Disaster Blaster: discover planets, recruit allies.">
                        <Image
                            src="gifs/db-store-discover.gif"
                            width="100%"
                            sx={{
                                marginTop: isSmallScreen ? "3rem" : "0",
                                borderRadius: "2rem",
                                boxShadow: `0 0 1rem ${paragraphTitleColor}, 0 0 2rem ${paragraphTitleColor}`
                            }}
                        />
                    </div>
                </Row>
            </ContentPanel >

            <ContentPanel backgroundImageSrc={"images/db_panel_background.jpg"}>
                <Row sx={{
                    backgroundColor: `${textBackgroundColor2}`,
                    padding: "2rem",
                    borderRadius: "1rem",
                    boxShadow: "0 0 1rem #000, 0 0 2rem #000",
                }}>
                    <div title="Disaster Blaster: unlock new upgrades.">
                        <Image
                            src="gifs/db-store-upgrade.gif"
                            width="100%"
                            sx={{
                                marginBottom: isSmallScreen ? "3rem" : "0",
                                borderRadius: "2rem",
                                boxShadow: `0 0 1rem ${paragraphTitleColor}, 0 0 2rem ${paragraphTitleColor}`
                            }}
                        />
                    </div>
                    <Paragraph>
                        <DisasterBlasterParagraphTitle
                            borderColor={paragraphTitleBorderColor}
                            textColor={paragraphTitleColor}
                            backgroundColor={paragraphBackgroundColor}
                        >
                            {Localization.get("disaster_blaster_upgrade_title")}
                        </DisasterBlasterParagraphTitle>
                        <ParagraphBody center={isSmallScreen}>
                            {Localization.get("disaster_blaster_upgrade_description")}
                        </ParagraphBody>
                    </Paragraph>
                </Row>
            </ContentPanel>
        </>
    );
}


export function DisasterBlasterPage() {
    return (
        <>
            <TrailerSection />
            <AboutGameSection />
            <ContactInfoSection />
        </>
    );
}
