import React, { useContext } from "react";
import {
  ContentPanel,
  ContentPanelTitle,
  Row,
} from "../component_library/layout";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import {
  Paragraph,
  ParagraphBody,
  ParagraphTitle,
} from "../component_library/paragraph";
import { List, ListItem, ListItemIcon } from "@mui/material";
import { FacebookIcon, TikTokIcon, InstagramIcon, LinkedInIcon, YouTubeIcon, JarsickWebsiteIconButton } from "../components/jarsick_website_button";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { StyleContext } from "../component_library/contexts";
import { Localization } from "../component_library/localization";
import { ReactComponent as FacebookIconSvg } from "../vectors/facebook-icon.svg";
import { ReactComponent as TikTokIconSvg } from "../vectors/tiktok-icon.svg";
import { ReactComponent as InstagramIconSvg } from "../vectors/instagram-icon.svg";
import { ReactComponent as LinkedInIconSvg } from "../vectors/linkedin-icon.svg";
import { ReactComponent as YouTubeIconSvg } from "../vectors/youtube-icon.svg";

export function ContactInfoSection() {
  const theme = useTheme();
  const styleContext = useContext(StyleContext);
  const iconColor1 = theme.palette.primary.main;
  const iconColor2 = theme.palette.secondary.main;
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box id="contacts">
      <ContentPanel color={theme.palette.background.paper}>
        <Row center={!isSmallScreen}>
          <Paragraph>
            <ParagraphTitle> {Localization.get("social_media_title")} </ParagraphTitle>
            <ParagraphBody>
              <JarsickWebsiteIconButton
                sx={{ mr: 2 }}
                color={iconColor1}
                onClick={() => window.open("https://www.facebook.com/people/Jarsick/100063487452989/?locale=id_ID")}
              >
                <FacebookIconSvg width={"100%"} height={"100%"} />
              </JarsickWebsiteIconButton>
              <JarsickWebsiteIconButton
                sx={{ mr: 2 }}
                color={iconColor2}
                onClick={() => window.open("https://www.tiktok.com/@jarsick_official")}
              >
                <TikTokIconSvg width={"100%"} height={"100%"} />
              </JarsickWebsiteIconButton>
              <JarsickWebsiteIconButton
                sx={{ mr: 2 }}
                color={iconColor1}
                onClick={() => window.open("https://www.instagram.com/jarsick_official")}
              >
                <InstagramIconSvg width={"100%"} height={"100%"} />
              </JarsickWebsiteIconButton>
              <JarsickWebsiteIconButton
                sx={{ mr: 2 }}
                color={iconColor2}
                onClick={() => window.open("https://www.linkedin.com")}
              >
                <LinkedInIconSvg width={"100%"} height={"100%"} />
              </JarsickWebsiteIconButton>
              <JarsickWebsiteIconButton
                sx={{ mr: 2 }}
                color={iconColor1}
                onClick={() => window.open("http://www.youtube.com/@jarsickgames6422")}
              >
                <YouTubeIconSvg width={"100%"} height={"100%"} />
              </JarsickWebsiteIconButton>
            </ParagraphBody>
          </Paragraph>
          <Paragraph>
            <ParagraphTitle> {Localization.get("contacts_title")} </ParagraphTitle>
            <ParagraphBody>
              {Localization.get("email_address")}
            </ParagraphBody>
          </Paragraph>
        </Row>
      </ContentPanel>
    </Box>
  );
}
