import { Scale } from "@mui/icons-material";
import { Button, colors, Icon, IconButton, SvgIcon, useTheme } from "@mui/material";


export const JarsickWebsiteButton = ({ onClick, color = "white", size = "medium", sx, children }) => {

    const hoverStyle = {
        border: '2px solid',
        boxShadow: `0 0 .4rem white, 0 0 .4rem white, 0 0 2rem ${color}, inset 0 0 0.7rem ${color}`,
        textShadow: `0 0 .1rem white`,
    };

    return (
        <Button
            onClick={onClick}
            variant="outlined"
            size={size}
            sx={{
                ...sx,
                backgroundColor: "rgba(0, 0, 0, .3)",
                border: '2px solid',
                color: `${color}`,
                '&:hover': hoverStyle,
            }}>
            {children}
        </Button>
    );
}

export const JarsickWebsiteIconButton = ({ color = "white", onClick, sx, children }) => {

    const iconHoverStyle = {
        filter: `drop-shadow(0 0 2px #fff) drop-shadow(0 0 2px ${color})`
    }


    return (
        <IconButton
            onClick={onClick}
            fontSize="large"
        >
            <SvgIcon
                color={color}
                fontSize="large"
                sx={{
                    filter: 'none',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': iconHoverStyle
                }}
            >
                {children}
            </SvgIcon>
        </IconButton>
    );

};

