import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header";
import { HomePage } from "./pages/home_page";
import strings_en from "./languages/strings_en.json";
import strings_it from "./languages/strings_it.json";
import strings_es from "./languages/strings_es.json";
import strings_fr from "./languages/strings_fr.json";
import strings_ja from "./languages/strings_ja.json";
import strings_zh from "./languages/strings_zh.json";
import strings_de from "./languages/strings_de.json";
import { Localization } from "./component_library/localization";
import { Footer } from "./component_library/footer";
import { useTheme } from "@emotion/react";
import { DisasterBlasterPage } from "./pages/disaster_blaster_page";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLcthcznP8hnOpP2ZymDYnUNtAkxkLCO4",
  authDomain: "jarsick-website-d100e.firebaseapp.com",
  projectId: "jarsick-website-d100e",
  storageBucket: "jarsick-website-d100e.appspot.com",
  messagingSenderId: "34656346267",
  appId: "1:34656346267:web:4186f34bf6195573b3263e",
  measurementId: "G-3E5MST1YR5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function App() {
  Localization.addLanguage("en", strings_en);
  Localization.addLanguage("it", strings_it);
  Localization.addLanguage("es", strings_es);
  Localization.addLanguage("fr", strings_fr);
  Localization.addLanguage("ja", strings_ja);
  Localization.addLanguage("zh", strings_zh);
  Localization.addLanguage("de", strings_de);

  const theme = useTheme();
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/disasterblaster" element={<DisasterBlasterPage />} />
      </Routes>
      <Footer color={theme.palette.background.paper} >©2024 Jarsick Games</Footer>
    </Router>
  );
}


export default App;
